.ribbonDiv {
  background-color: var(--color-grey-light);
  color: black;
  text-align: center;
  max-height: 0px;
  transition: all 0.2s ease-in-out 0s;
  padding: 0 5px;
  display: flex;
  position: relative;
}

body[data-domain='camperlab'] .ribbonDiv {
  display: block;
  color: white;
}

body[data-domain='nnormal'] .ribbonDiv {
  background-color: var(--color-black);
  font-family: var(--font-primary);
  font-weight: 300;
  font-size: 1.375rem;
  display: block;
}

body[data-domain='nnormal'] .ribbonDiv :global .ant-carousel {
  color: var(--color-white);
  font-family: var(--font-primary);
  font-weight: 300;
  font-size: 1.375rem;
}

.ribbonDivShow {
  padding: 5px;
  max-height: 100px;
}

.ribbonDiv :global .ant-carousel {
  line-height: 1;
}
.ribbonDiv :global .slick-slider {
  margin-bottom: 0px;
  margin-top: 0px;
}
.ribbonContent {
  display: flex;
  align-items: center;
}

body[data-domain='nnormal'] .ribbonContent {
  justify-content: center;
}

.ribbonContent > div {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 25px);
}

.chevron:hover {
  cursor: pointer;
}
.ribbonContent > div > .chevron:first-child {
  transform: rotate(90deg);
  zoom: 0.6;
  position: relative;
  top: -2px;
  padding-top: 5px;
}
.ribbonContent > div > .chevron:not(:first-child) {
  transform: rotate(-90deg);
  zoom: 0.6;
  position: relative;
  top: -2px;
  padding-top: 5px;
}
.ribbonItem {
  font-size: 0.75em;
  padding-bottom: 1px;
}
.ribbonItemLink {
  font-size: 0.75em;
  padding-bottom: 1px;
  cursor: pointer;
}
.ribbonDiv :global a {
  color: unset;
}
.chevronExpand {
  margin-right: 10px;
  zoom: 0.7;
  transition: transform 0.3s;
  height: 100%;
  padding-bottom: 4px;
}

body[data-domain='nnormal'] .chevronExpand {
  position: absolute;
  margin-right: 0px;
  right: 10px;
}

.chevronExpand > svg {
  margin: auto 0;
}
.chevronExpanded {
  transform: rotate(180deg);
}
.chevronExpand:hover {
  cursor: pointer;
}
.expandedContent {
  z-index: 100;
  position: absolute;
  padding: 40px;
  font-size: 0.75em;
  background-color: var(--color-grey-light);
  min-width: 100%;
  text-align: center;
}

body[data-domain='nnormal'] .expandedContent,
body[data-domain='camperlab'] .expandedContent {
  background-color: var(--color-black);
  color: var(--color-white);
}

body[data-domain='nnormal'] .expandedContent > *,
body[data-domain='camperlab'] .expandedContent > * {
  color: var(--color-white);
}

.ribbonCarousel {
  width: 50%;
  display: block;
  align-self: center;
}

.ribbonWidgets {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ribbonWidgets > a,
.ribbonWidgets > span {
  font-size: var(--description-size);
}

.ribbonWidgets > span,
.ribbonWidgets > div,
.ribbonWidgets > a {
  margin: 0.25em 1em;
}

.ribbonCamperlab {
  background-color: var(--color-black);
}

.ribbonCamperlab .ribbonWidgets > span {
  color: var(--color-white);
}

.ribbonCamperlab .ribbonWidgets :global .account-icon > span {
  color: var(--color-white);
}

.ribbonCamperlab .ribbonContent .ribbonItemLink,
.ribbonCamperlab .ribbonContent .ribbonItem,
.ribbonCamperlab .chevron,
.ribbonCamperlab .chevronExpand {
  color: var(--color-white) !important;
}

body[data-domain='camperlab'] .ribbonItemLink,
body[data-domain='camperlab'] .ribbonItem {
  font-weight: bold;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.ribbonCamperlab :global a {
  color: var(--color-white) !important;
}

@media screen and (min-width: 1024px) {
  body[data-domain='nnormal'] .ribbonItemLink {
    font-size: 0.875rem;
  }

  body[data-domain='nnormal'] .ribbonDiv :global .ant-carousel {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 768px) {
  .ribbonDiv {
    flex-direction: column;
  }

  .ribbonCarousel {
    width: 100%;
  }

  .ribbonWidgets {
    display: none;
  }
}
